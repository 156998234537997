<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.BusinessRuleActions",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div v-if="this.businessRuleTriggerTypeId == 5">
      <button
        class="btn btn-success dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "BusinessRuleActions.NewTitle",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <button type="button" class="dropdown-item" @click="goToSendReport()">
            {{
              $t(
                "BusinessRuleActions.SendReport",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToSendXtraReport()"
          >
            {{
              $t(
                "BusinessRuleActions.SendXtraReport",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToSendDashboard()"
          >
            {{
              $t(
                "BusinessRuleActions.SendDashboard",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
    </div>
    <div v-else>
      <button
        class="btn btn-success dropdown-toggle"
        type="button"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "BusinessRuleActions.NewTitle",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToCreateRecord()"
          >
            {{
              $t(
                "BusinessRuleActions.CreateRecord",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToUpdateRecord()"
          >
            {{
              $t(
                "BusinessRuleActions.UpdateRecord",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToSendNotification()"
          >
            {{
              $t(
                "BusinessRuleActions.SendNotification",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item"
            @click="goToSendHTTPRequest()"
          >
            {{
              $t(
                "BusinessRuleActions.SendHTTPRequest",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="businessRuleActionList"
    @changeOrderSuccessful="getBusinessRuleActions"
    @onEditButtonClick="onEditButtonClick"
  />
</template>
<script>
export default {
  name: "CustomObjectBusinessRuleActions",
  data() {
    return {
      sendReport: "SendReport",
      businessRuleTriggerTypeId: "",
      businessRuleActionList: [],
      gridSettings: {
        action: "CustomObjectBusinessRuleActions",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [{ key: "id", value: "businessRuleId" }],
        routeRequiredParameters: [
          {
            key: "businessRuleActionId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "businessRuleId",
            value: "businessRuleId",
            isRouteParameter: true,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Brs-BusinessRuleActionDelete",

        allowDragAndDrop: true,
        changeOrderUrl: "/Brs-BusinessRuleActionChangeOrder",
        changeOrderModelParentProperties: [
          { key: "parentPublicId", value: "customObjectId" },
        ],
        autoOrderProcess: false,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BusinessRule.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRuleActions.BusinessRuleActionType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessRuleActionType",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    goToCreateRecord: function () {
      localStorage.setItem("businessRuleActionType", "CreateRecord");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionCreateRecord",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "CreateRecord",
        },
      });
    },
    goToUpdateRecord: function () {
      localStorage.setItem("businessRuleActionType", "UpdateRecord");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionUpdateRecord",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "UpdateRecord",
        },
      });
    },
    goToSendNotification: function () {
      localStorage.setItem("businessRuleActionType", "SendNotification");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionSendNotification",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "SendNotification",
        },
      });
    },
    goToSendHTTPRequest: function () {
      localStorage.setItem("businessRuleActionType", "SendHTTPRequest");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionSendHttpRequest",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "SendHTTPRequest",
        },
      });
    },
    goToSendReport: function () {
      localStorage.setItem("businessRuleActionType", "SendReport");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionSendReport",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "SendReport",
        },
      });
    },
    goToSendXtraReport: function () {
      localStorage.setItem("businessRuleActionType", "SendXtraReport");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionSendXtraReport",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "SendXtraReport",
        },
      });
    },
    goToSendDashboard: function () {
      localStorage.setItem("businessRuleActionType", "SendDashboard");
      this.$router.push({
        name: "CustomObjectNewBusinessRuleActionSendDashboard",
        params: {
          customObjectId: this.$route.params.customObjectId,
          pageType: "SendDashboard",
        },
      });
    },
    onEditButtonClick(rowData) {
      var businessRuleId = this.$route.params.businessRuleId,
        customObjectPublicId = this.$route.params.customObjectId,
        businessRuleActionId = rowData.publicId,
        routeName = "";

      localStorage.setItem(
        "businessRuleActionType",
        rowData.businessRuleActionType
      );

      if (rowData.businessRuleActionType == "CreateRecord") {
        routeName = "CustomObjectEditBusinessRuleActionCreateRecord";
      } else if (rowData.businessRuleActionType == "UpdateRecord") {
        routeName = "CustomObjectEditBusinessRuleActionUpdateRecord";
      } else if (rowData.businessRuleActionType == "SendNotification") {
        routeName = "CustomObjectEditBusinessRuleActionSendNotification";
      } else if (rowData.businessRuleActionType == "SendHTTPRequest") {
        routeName = "CustomObjectEditBusinessRuleActionSendHttpRequest";
      } else if (rowData.businessRuleActionType == "SendReport") {
        routeName = "CustomObjectEditBusinessRuleActionSendReport";
      } else if (rowData.businessRuleActionType == "SendXtraReport") {
        routeName = "CustomObjectEditBusinessRuleActionSendXtraReport";
      } else if (rowData.businessRuleActionType == "SendDashboard") {
        routeName = "CustomObjectEditBusinessRuleActionSendDashboard";
      }

      this.$router.push({
        name: routeName,
        params: {
          customObjectId: customObjectPublicId,
          businessRuleId: businessRuleId,
          businessRuleActionId: businessRuleActionId,
        },
      });
    },
    getBusinessRuleActions() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleActionGetBusinessRuleActionsByBusinessRule?id={0}&coId={1}",
            this.$route.params.businessRuleId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessRuleActionList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.getBusinessRuleActions();
  },
};
</script>
